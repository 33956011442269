.nav-header {
  height: 5rem;
  width:16rem;
  background-color: white;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}

.deznav {
  width:16rem;
  padding-top:70px;
  background-color: white;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}

.header {
  height: 5rem;
  background-color: white;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}

.content-body {
  margin-left:16rem;
  padding-top: 4.7rem !important;
  background-color: #fcfcfc;
}

/* HOME */

.bordered-card {
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 8px;
}

.circle-1 {
  height: 25px;
  width: 25px;
  content: "";
  border-radius: 100%;
  transform: translate(-50%, -50%);
}

.card-bold-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
  color: #1a1a1a;
}

.btn {
  border-radius: 8px;
}

/* Profile */
.jto-top-profile-item {
  position: absolute;
  top: 10%;
  left: 15%;
}

.profile-caption-1 figcaption {
  position: absolute;
  bottom: 65px;
  left: 0;
  right: 0;
}
.qr-svg {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.qr-box {
  width: 100px;
  height: 100px;
}

.resizable-content {
  min-height: 100px;
  min-width: 100px;
  resize: both;
  overflow: auto;
  max-height: fit-content;
  max-width: fit-content;
}

.react-input-emoji--input {
  max-height: 200px;
  min-height: 150px;
}

.form-control {
  border: 1px solid #ccc;
  border-radius: 0;
}

.required-field::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.rbt-input-wrapper input {
  display: block;
  width: 60px;
  margin: 10px auto;
  padding: 3px;
}

.PhoneInputInput {
  border-radius: 0;
  background: #fff;
  border: 1px solid #6e6e6e;
  color: #6e6e6e;
  height: 56px;
  padding-left: 15px;
}

.form-control {
  border-radius: 0;
  background: #fff;
  border: 1px solid #6e6e6e;
  color: #6e6e6e;
}

.form-multi-select input {
  height: 40px;
  border-radius: 0;
  background: #fff;
  border: 1px solid #6e6e6e;
  color: #6e6e6e;
}

.table thead th {
  border-bottom-width: 1px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 10px;
}

.table td {
  padding: 2px 5px;
  white-space: nowrap;
  margin: 0;
}
.table {
  font-size: 0.7rem !important;
}

.MuiOutlinedInput-root {
  width: 100%;
  border-radius: 5px !important;
}

.MuiOutlinedInput-root input {
  border: none;
  height: 45px;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, 0px) scale(0.6) !important;
}

.svg-test{
  width:270px;
  filter: invert(10%) sepia(89%) saturate(6391%) hue-rotate(360deg) brightness(73%) contrast(109%);
  /* filter: invert(88%) sepia(21%) saturate(935%) hue-rotate(123deg) brightness(85%) contrast(97%); */
  }

  .media-body p{
    font-size: 12px !important;
  }

  .media-body h4{
  font-size: 12px !important;
  }

  .header .header-content{
    padding-left:0px !important;
  }

  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
    padding-left: 10rem;
}

.widget-stat{
  cursor: pointer !important;
}